<template>
	<div class="menu-wrap">
		<div class="menu">
			<div class="ayz_name">건강보험자격득실 OCR 분석내용</div>
			<div class="profile">
				<p class="name">{{data.mberNm}}</p>
				<p class="age">({{data.gender | gender}} / {{data.birthYear | birthYear}})</p>
				<p class="belong">{{data.corpNm}} / <SelectComp type="text" :value="data.empDivCd" cdId="PRO102"/></p>
			</div>
			<div class="attach_name"><img src="/images/admin/attach_file_icon.png" alt="첨부파일아이콘"/>첨부파일명</div>
			<template v-if="data && data.attaFileRelList && data.attaFileRelList.length > 0">
				<div class="file_name" @click="clickItem(item)" v-for="item in data.attaFileRelList" :key="item.attaFileSeq">
					<p :class="{click: attaFileSeq == item.attaFileSeq}">{{item.orgFileNm}}</p>
					<span class="click" :class="{show: attaFileSeq == item.attaFileSeq}">&#9654;</span>
					<div class="ocr_mark" v-if="item.recogResultYn == 'Y'">OCR 검증완료</div>
				</div>
			</template>
			<div class="file_name" v-else>
				등록된 첨부파일이 없습니다.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		tecMberSeq: {},
		resumeAttaFileDivCd : {},
		clickDoc: Function,
	},
	data() {
		return {
			data: {},
			attaFileSeq: '',
			checkTime: null,
		}
	},

	mounted() {
		this.$.http('/api/mem/adm/prf/getOcrList', { tecMberSeq : this.tecMberSeq, resumeAttaFileDivCd : this.resumeAttaFileDivCd })
			.then(res => {
				this.data = res.data;
				for(var i in this.data.attaFileRelList){
					var item = this.data.attaFileRelList[i];
					if(item.recogResultYn == 'Y'){
						this.clickItem(item);
						break;
					}
				}
			})
			.catch( this.$.httpErrorCommon );
	},

	methods : {
		clickItem(item){
			if(item.recogResultYn == 'Y'){
				this.attaFileSeq = item.attaFileSeq;
				this.clickDoc(item);
			} else {
				this.checkTime = new Date().add(3, 'MINUTE');
				this.$.http('/api/mem/adm/prf/recognizeNhis', { tecMberSeq : this.tecMberSeq, attaFileSeq : item.attaFileSeq })
					.then(res => {
						this.checkTime = null;
						if(res.data && res.data.list){
							item.recogResultYn = 'Y';
							this.clickItem(item);
						}
					})
					.catch( err => {
						if(err.response && err.response.status == 504){
							//alert('OCR 분석이 지연되고 있습니다. 잠시 후 다시 시도해주시기 바랍니다.');
							this.checkItem(item);
						} else {
							this.checkTime = null;
							this.$.httpErrorCommon(err);
						}
					});
			}
		},
		checkItem(item){
			this.$.loading(true);
			if(this.checkTime < new Date()){
				this.checkTime = null;
				alert('OCR 분석이 지연되고 있습니다. 잠시 후 다시 시도해주시기 바랍니다.');
				this.$.loading(false);
			} else {
				setTimeout(() => {
					this.$.http('/api/mem/adm/prf/learningOCR', { tecMberSeq : this.tecMberSeq, attaFileSeq : item.attaFileSeq })
						.then(res => {
							//console.log('learningOCR', res);
							if(res.data.type){
								this.checkTime = null;
								item.recogResultYn = 'Y';
								this.clickItem(item);
							} else {
								this.checkItem(item);
							}
						}).catch(this.$.httpErrorCommon);
				}, 3000);
			}

		}
	},
}
</script>