<template>
	<div class="main-contents hr agent">
		<div class="tit">건강보험자격득실확인서 OCR 인식내용
			<img src="/images/admin/back_btn_ocr.png" alt="뒤로가기" @click="$router.go(-1)">
		</div>
		<p class="caution" v-if="!data" style="color:red;">※ 건강보험자격득실확인서를 선택해 주세요.</p>
		<template v-else>
			<p class="caution">※ 입력데이터와 인식데이터 값이 다를 수 있습니다. 인식데이터를 확인해 주세요!</p>
			<div class="Board type3 ocr">
				<table class="Board_type3 admin">
					<colgroup>
						<col width="12%">
						<col width="30%">
						<col width="20%">
						<col width="20%">
						<col width="18%">
					</colgroup> 
					<thead>
						<tr>
							<th style="padding-left: 20px;">구분</th>
							<th>회사명</th>
							<th>입사 / 취득</th>
							<th>퇴사 / 상실</th>
							<th>OCR 인식결과</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!data.result || data.result.length == 0"><td colspan="5" class="none">입력데이터가 없습니다!</td></tr>
						<tr v-else v-for="(item, idx) in data.result" :key="idx" @click="drawResult(idx, item)">
							<td class="score">
								<P class="input">입력 &#9654;</P>
								<P class="output">인식 &#9654;</P>
							</td>
							<td>
								<P class="input">{{item.companyNm}}</P>
								<P class="output"><span v-for="(match, ii) in item.list[0].companyNm.match2" :key="idx + '_' + ii" :class="{false:!match}">{{item.list[0].companyNm.text.charAt(ii)}}</span></P>
							</td>
							<td>
								<P class="input">입사 {{item.workStartYyyymm}}</P>
								<P class="output">취득 <span v-for="(match, ii) in item.list[0].workStart.match2" :key="idx + '_' + ii" :class="{false:!match}">{{item.list[0].workStart.text.charAt(ii)}}</span></P>
							</td>
							<td>
								<P class="input">퇴사 {{item.workEndYyyymm}}</P>
								<P class="output">상실 <span v-for="(match, ii) in item.list[0].workStart.match2" :key="idx + '_' + ii" :class="{false:!match}">{{item.list[0].workStart.text.charAt(ii)}}</span></P>
							</td>
							<td class="score">
								<P class="output" v-if="item.nhisOcrRecogResultYn == 'Y'">인식완료 &#9711;</P>
								<P class="output cf" v-else-if="item.nhisOcrRecogResultYn == 'C'">확인필요 &#9651;</P>
								<P class="output cf" v-else>인식실패 X</P>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="ocr_file" :style="{ height: window.innerHeight + 'px', overflow: 'auto'}">
				<canvas v-for="(page, idx) in data.base.list" :key="idx" ref="canvasImg" style="width: 100%;" :width="page.width" :height="page.height"></canvas>
			</div>
		</template>

		<!-- 기술회원 메모 -->
		<tec-memo :tecMberSeq="tecMberSeq"/>
	</div>
</template>
<script>
import menu from "@/components/highpro/adm/MenuOcrList.vue";
import tecMemo from "@/components/highpro/adm/TecMemo.vue";

export default {
	components: { tecMemo },
	data() {
		return {
			tecMberSeq: this.$route.query.tecMberSeq,
			resumeAttaFileDivCd: this.$route.query.resumeAttaFileDivCd,
			data: null,
			window: window,
			selectRow : -1,
        };
    },
    mounted(){
        this.$emit('setViewConfig', { classNm: 'ocr', component: menu, params:{ tecMberSeq: this.tecMberSeq, resumeAttaFileDivCd : this.resumeAttaFileDivCd, clickDoc: this.clickDoc }})
    },
    destroyed(){
        this.$emit('clearViewConfig')
	},
	methods: {
		clickDoc(item){
			//console.debug('clickDoc', item);
			this.$.http('/api/mem/adm/prf/recognizeNhisDocs', { tecMberSeq : this.tecMberSeq, attaFileSeq : item.attaFileSeq })
				.then(res => {
					//console.debug('clickDoc', res);
					this.data = res.data;
					this.$nextTick(() => {
						for(var p in this.data.base.list){
							var page = this.data.base.list[p];
							page.nhis = this.data.nhis[p];
							
							page.image = new Image();
							page.canvas = this.$refs.canvasImg[p];
							page.ctx = page.canvas.getContext('2d');
							
							page.canvas.addEventListener("click", function() {
								//console.log('canvas click', e);
								//console.log("clickCanvas_"+p, e.layerX / ($(page.canvas).width()), e.layerY, page.canvas);
							});
							
							//console.log(page)
							//page.lWidth = $(page.canvas).width();;
							//page.lHeight = page.lWidth * page.height / page.width;
							//$(page.canvas).height(page.lHeight);
							//$(page.canvas).attr("width" , page.width);
							//$(page.canvas).attr("height", page.height);
							this.loadCanvas(p, page);
						}
					});
				})
				.catch( this.$.httpErrorCommon );
		},
		
		loadCanvas(idx, page){
			//console.log("loadCanvas", idx, page);
			//param
			var tecMberSeq = this.tecMberSeq;
			var fileKey = page.fileKey;

			//option
			var responseType = 'blob';
			this.$.http('/api/mem/adm/prf/downFileImg', {tecMberSeq, fileKey}, { responseType }, 'GET').then(res => {
					//console.log('ImageComp', 'getImage success', res)
					if(res.data.size > 0){
						var drawPage = this.drawPage;
						page.image.onload = function(){ drawPage(idx, page); }
						page.image.src = URL.createObjectURL(res.data);
					//} else {
					}
				}).catch(() => {
					//console.log('ImageComp', 'getImage error')
					this.result = false;
				});
		},

		drawPage(idx, page, check){
			//console.log("drawPage", idx, page, check);
			page.ctx.clearRect(0, 0, page.width, page.height);
			page.ctx.drawImage(page.image , 0, 0, page.width, page.height);
			if(check){
				this.drawTextArea(idx, page);
				this.drawLine(idx, page);
			}
		},

		drawTextArea(idx, page){
			//console.log("drawTextArea", idx, page);
			page.ctx.strokeStyle = '#00AA00';
			for(var i in page.list){
				if(page.list[i].list){
					for(var j in page.list[i].list){
						var area = page.list[i].list[j];
						page.ctx.strokeRect(area.left, area.top, area.right - area.left, area.bottom-area.top);
					}
				} else {
					var area2 = page.list[i];
					page.ctx.strokeRect(area2.left, area2.top, area2.right - area2.left, area2.bottom-area2.top);
				}
			}
		},

		drawLine(idx, page){
			//console.log("drawLine", idx, page);
			page.ctx.strokeStyle = '#FF0000';
			for(var i in page.nhis){
				for(var j in page.nhis[i]){
					var area = page.nhis[i][j];
					page.ctx.strokeRect(area.left, area.top, area.right - area.left, area.bottom-area.top);
				}
			}
		},
		drawResult(idx, item){
			//console.log("drawResult", item);
			var check = this.selectRow != idx;
    		this.selectRow = (check) ? idx : -1;
			var pages = this.data.base.list;

			for(var p in pages){
				this.drawPage(p, pages[p], check);
			}

			if(!check) return;
			for(var i in item.list){
				var area = item.list[i];
				var page = pages[area.page];
				
				var r=0, g=0, b=0;
				//r = 255 * (1 - area.match);
				//g = 0;
				//b = 255 * area.match;
				if(area.match > 0.5){
					b = 200 * (2 * area.match - 1);
					g = 200 * 2 * (1 - area.match);
				} else {
					g = 180 * (2 * area.match);
					r = 200 * (1 - 2 * area.match);
				}
				
				page.ctx.fillStyle = "rgb(" + r + ", " + g + ", " + b + ")";
				page.ctx.textBaseline = "middle";
				page.ctx.textAlign = "left";
				page.ctx.font = (Math.min((page.width - area.workEnd.right)/3, area.workEnd.bottom - area.workEnd.top) - 2)+'px 나눔명조';
				
				page.ctx.fillRect(area.workEnd.right, area.workEnd.top, page.width - area.workEnd.right, area.workEnd.bottom-area.workEnd.top);
				page.ctx.fillStyle = "#FFFFFF";
				
				var x = area.workEnd.right;
				var y = area.workEnd.top + (area.workEnd.bottom - area.workEnd.top)/2;
				//console.log("drawResult", i, (area.match * 100).toFixed(2) + "%", x, y, r, g, b);
				page.ctx.fillText((area.match * 100).toFixed(2) + "%", x, y);
			}
		},
	},
	computed: {
	}
};
</script>
